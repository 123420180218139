import { ActiveConcert } from "../../constants/dataConstant"
import ButtonElement from "../../components/ButtonElement"
import useGA from "../../hooks/useGA"
import useClevertap from "../../hooks/useClevertap"

type ActiveConcertElementMobileProps = {
    concert: ActiveConcert
    index: number
}

const ActiveConcertElementMobile = ({concert, index}: ActiveConcertElementMobileProps) => {

    const { recordGAEvent } = useGA()
    const { handleEventPushClick } = useClevertap()

    const handleClick = () => {
        recordGAEvent(`PlayNow_concert_${index+1}_MZ`)
        handleEventPushClick(`PlayNow_concert_${index+1}_MZ`, {})
        window.open(`https://muzify.com/quiz/${concert.artist_id}`, "_blank");
    }

    return (
        <div className="w-[160px] py-2 flex flex-shrink-0 flex-col justify-center items-center bg-[#D9D9D9]">
            <img src={concert.image} className="w-[140px] h-[140px] object-cover" />
            <div className="font-indie-flower text-[18px] leading-[100%] text-[#000000] mt-2">
                {concert.artist}
            </div>
            <div className="font-indie-flower text-[16px] leading-[100%] text-[#000000] mt-1">
                {`${concert.date} ${concert.city}`}
            </div>
            <div className="flex justify-center items-center mt-2">
                <ButtonElement text="Play Now" disabled={false} clickHandler={handleClick} />
            </div>
        </div>
    )
}

export default ActiveConcertElementMobile
