import { useEffect, Suspense, lazy } from 'react';
import { Route, Routes } from "react-router-dom";
import ReactGA from 'react-ga4';
import useGA from './hooks/useGA';
import { GA_EVENTS, CLEVERTAP_ACCOUNT_ID } from "./constants/dataConstant";
import clevertap from "clevertap-web-sdk";
import HomePage from './screens/Homepage';
import PrivacyPolicy from './screens/privacyPolicy';
import TermsAndConditions from './screens/termsAndConditions';
import ConcertsPage from './screens/concerts';

// const HomePage = lazy(() => import('./screens/Homepage'));
// const PrivacyPolicy = lazy(() => import('./screens/privacyPolicy'));
// const TermsAndConditions = lazy(() => import('./screens/termsAndConditions'));
// const ConcertsPage = lazy(() => import('./screens/concerts'));

function App() {
  const { recordGAEvent } = useGA();

  useEffect(() => {
    clevertap.setLogLevel(3);
    clevertap.init(CLEVERTAP_ACCOUNT_ID);
    ReactGA.initialize("G-SGRZPVFCQ1");
    ReactGA.send({ hitType: "pageview", page: window?.location?.pathname ?? "/" });
    recordGAEvent(GA_EVENTS.MUZIFY_LANDING_PAGE_VIEW);
  }, []);

  return (
    // <Suspense fallback={<div>Loading...</div>}>
      <Routes>
        <Route index path="/" element={<HomePage />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/terms-and-conditions" element={<TermsAndConditions />} />
        <Route path="/concerts" element={<ConcertsPage />} />
        <Route path="/concerts/:artistName" element={<ConcertsPage />} />
      </Routes>
    // </Suspense>
  );
}

export default App;
