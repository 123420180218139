import { useLocation } from "react-router";

const Disclaimer = () => {
    return (
        <div className="font-inter leading-[110%] font-extralight italic text-[14px] md:text-[18px] text-center">
            <span className="text-[#FFFFFF] text-opacity-60">{`*Does `}</span>
            <span className="text-[#FFFFFF] text-opacity-100">{`NOT `}</span>
            <span className="text-[#FFFFFF] text-opacity-60">{`include travel & accommodation. Winners are expected to capture & share event snippets as part of this contest on socials.`}</span>
        </div>
    )
}

const HowToEnter = () => {

    const location = useLocation();

    const getInstagramHandles = () => {
        const sanitizedPath = location.pathname.replace(/[^a-zA-Z0-9]/g, '').toLowerCase();
    
        if (sanitizedPath.includes("anuvjain")) {
            return [
                { name: "Muzify", url: "https://www.instagram.com/muzifyhq/" },
                { name: "Anuv Jain", url: "https://www.instagram.com/anuvjain/" },
            ];
        } else if (sanitizedPath.includes("thepaperkites")) {
            return [
                { name: "Muzify", url: "https://www.instagram.com/muzifyhq/" },
                { name: "The Paper Kites", url: "https://www.instagram.com/thepaperkites/" },
            ];
        } else if (sanitizedPath.includes("bharatchauhan")) {
            return [
                { name: "Muzify", url: "https://www.instagram.com/muzifyhq/" },
                { name: "Bharat Chauhan", url: "https://www.instagram.com/bharatchauhanofficial/" },
            ];
        } else if (sanitizedPath.includes("adityarikhari")) {
            return [
                { name: "Muzify", url: "https://www.instagram.com/muzifyhq/" },
                { name: "Aditya Rikhari", url: "https://www.instagram.com/adityarikhari/" },
            ];
        } else {
            return [{ name: "Muzify", url: "https://www.instagram.com/muzifyhq/" }];
        }
    };    

    const instagramHandles = getInstagramHandles();

    return (
        <div className="w-full h-[618px] px-5 flex flex-col justify-evenly items-center bg-[rgba(6,6,6,0.7)] bg-opacity-10">
            <div className="flex justify-center items-center font-inter italic text-[24px] md:text-[36px] text-[#FFFFFF] font-medium">
                {`How to win`}
            </div>
            <div className=" w-full flex flex-col justify-start items-center font-inter text-[#FFFFFF] text-[16px] md:text-[18px] gap-3">
                <div className="w-full flex flex-row justify-center items-start">
                    <div className="w-[10%] flex justify-center items-center">{`1. `}</div>
                    <div className="w-[90%]">{`Click 'Play quiz!' and enjoy playing Muzify for your favourite artists`}</div>
                </div>
                <div className="w-full flex flex-row justify-center items-start">
                    <div className="w-[10%] flex justify-center items-center">{`2. `}</div>
                    <div className="w-[90%]">{`Sign up and save your fandom scorecards on the fan leaderboard. The more artists you play, the higher your chances are to win`}</div>
                </div>
                <div className="w-full flex flex-row justify-center items-start">
                    <div className="w-[10%] flex justify-center items-center">{`3. `}</div>
                    <div className="w-[90%]">{`Fill up your name and city in the concert deets Popup after sign up`}</div>
                </div>
                <div className="w-full flex flex-row justify-center items-start">
                    <div className="w-[10%] flex justify-center items-center">{`4. `}</div>
                    <div className="w-[90%]">{`Next, record your quiz and share it on Instagram or YouTube`}</div>
                </div>
                <div className="w-full flex flex-row justify-center items-start">
                    <div className="w-[10%] flex justify-center items-center">{`5. `}</div>
                    <div className="w-[90%]">Follow & Share your fandom scorecards on X & Instagram tagging
                    {instagramHandles.map((handle, index) => (
                            <span key={index}>
                                <a
                                    href={handle.url}
                                    target="_blank"
                                    rel="noreferrer"
                                    className="text-[#FEC330]" // Ensures it inherits color from parent or default styles
                                >
                                  {" "}  {handle.name} {" "}
                                </a>
                                {index < instagramHandles.length - 1 ? " and " : ""}
                            </span>
                        ))}
                        Share your fandom scorecards with 5 friends to challenge them to play the quiz and beat your score.</div>
                </div>
                <div className="w-full flex flex-row justify-center items-start">
                    <div className="w-[10%] flex justify-center items-center">{`6. `}</div>
                    <div className="w-[90%]">{`Winners will be updated via their email`}</div>
                </div>
            </div>
            <div className="mt-4 px-2 md:px-4 w-full">
                <Disclaimer />
            </div>
        </div>
    )
}

export default HowToEnter
