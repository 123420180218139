import useGA from "../../hooks/useGA";
import useClevertap from "../../hooks/useClevertap";
// import { FaApple } from "react-icons/fa";
import { GA_EVENTS } from "../../constants/dataConstant";

const CTAButton = () => {

    const { recordGAEvent } = useGA();
    const { handleEventPushClick } = useClevertap();

    const handleCTAClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        event.preventDefault();
        recordGAEvent(GA_EVENTS.STICKY_CTA_CLICK);
        handleEventPushClick(GA_EVENTS.STICKY_CTA_CLICK, {});
        window.open("https://muzify.onelink.me/PNu3/39ussg3x", "_blank");
    }

    return (
        <button onClick={(e) => handleCTAClick(e)}>
            <div className="inline-block p-[2px] rounded-full shadow-[0_2px_4px_1px_rgba(14,15,12,0.3)] bg-gradient-to-br from-[#FFEBBA] to-[#98751D]">
                <div className="py-1 md:py-2 px-2 md:px-4 rounded-full bg-[#0E0F0C] flex flex-row justify-center items-center font-inter font-semibold text-[9px] md:text-[14px] leading-[120%] text-[#FEC330]">
                    <div>Get App</div>
                </div>
            </div>
        </button>
    )
}

export default CTAButton;
