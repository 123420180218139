import { useState } from "react"
import { ActiveConcert } from "../../constants/dataConstant"
import ButtonElement from "../../components/ButtonElement"
import useGA from "../../hooks/useGA"
import useClevertap from "../../hooks/useClevertap"

type ActiveConcertElementDesktopProps = {
    concert: ActiveConcert
    index: number
}

const ActiveConcertElementDesktop = ({concert, index}: ActiveConcertElementDesktopProps) => {
    const [isHovered, setIsHovered] = useState(false)

    const { recordGAEvent } = useGA()
    const { handleEventPushClick } = useClevertap()

    const handleClick = () => {
        recordGAEvent(`PlayNow_concert_${index+1}_MZ`)
        handleEventPushClick(`PlayNow_concert_${index+1}_MZ`, {})
        window.open(`https://muzify.com/quiz/${concert.artist_id}`, "_blank");
    }

    return (
        <div className="relative w-[252.98px] h-[295px] flex flex-col flex-shrink-0 justify-center items-center bg-[#D9D9D9]"
            onMouseEnter={() => setIsHovered(true)} onMouseLeave={() => setIsHovered(false)}
        >
            <img src={concert.image} className="w-[225.87px] h-[210.67px] object-cover object-top" />
            <p className="font-indie-flower text-[24px] leading-[80%] text-[#000000] mt-4">
                {concert.artist}
            </p>
            <div className="font-indie-flower text-[18px] leading-[100%] text-[#000000] mt-1">
                {`${concert.date} ${concert.city}`}
            </div>

            {
                isHovered ? (
                    <div 
                        className={`absolute inset-0 bg-black flex justify-center items-center ${isHovered ? 'bg-opacity-80' : 'bg-opacity-0'}`} 
                    >
                        <ButtonElement text={"Play Now"} disabled={false} clickHandler={handleClick}/>
                    </div>
                ) : <></>
            }
        </div>
    )
}

export default ActiveConcertElementDesktop
