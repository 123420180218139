import MuzifyLogo from '../assets/images/MuzifyWhiteLogo.png';

const PrivacyPolicy = () => {
    return (
        <>
            <div className="flex w-full bg-[#4b4bea] pt-14 justify-center items-center ">
                <img src={MuzifyLogo} className="w-[180px]  md:w-[233.5px] h-[67.5px] md:h-[87.5px]" />
            </div>
            <h1 className="text-center mb-8 text-3xl bg-[#4b4bea] text-white p-10 w-full"><strong style={{ fontWeight: 'bold' }}>PRIVACY POLICY</strong></h1>
            <div className='md:m-10 md:px-16 m-2 px-2'>
                <p>Asset Works Ventures Pte. Ltd. (<strong>“Company”</strong> or <strong>“we”</strong> or <strong>“us”</strong> or <strong>“our”</strong> and their connotations) operates a website (<a href='https://www.asset.money/' className='text-blue-500 underline'>Asset Money</a>) (referred to as, the <strong>“Website”</strong>) and a mobile application (together called as <strong>“Platform”</strong>), which is a Platform dedicated to the transaction of digital assets and the provision of related services (hereinafter referred to as ‘the <strong>Services</strong>’).
                </p>
                <p className="mt-1">All natural persons or other subjects who log onto this Website shall be users of this Website. For the convenience of wording in this Agreement, the users are referred to as <strong>‘users</strong> or <strong>you’</strong> or any other applicable forms of the second-person pronouns. You and we are collectively referred to as “<strong>Parties</strong>”, and individually as “<strong>Party</strong>”.</p>
                <p className="mb-5 mt-1">Our users’ privacy is extremely important to Us. We recognize the privacy concerns of the users and hence as a commitment to provide safe and secure experience on Our Website, we have formulated this Privacy Policy to help Our Users make an informed decision.
                </p>
                <p><strong className='pt-5' style={{ color: 'black', fontWeight: 'bold', marginTop: '5px' }}>PURPOSE</strong></p>
                <p className="mb-5 mt-1">The policy (“<strong>Privacy Policy</strong>”) specifies the kind of data of yours that we might gather through your log into this Website, your enrollment with this Site, and/or use the services we offer, as well as how we will utilize and protect the information so collected. 
                </p>

                <p><strong style={{ color: 'black', fontWeight: 'bold' }}>LEGAL BASIS</strong></p>
                <p className="mb-2 mt-1">This Policy is meant to help the users understand what information we collect, why we collect it, what information we disclose and how the users can update, manage, export, and delete their information. It shall be read with our Terms found here ([●]) and in compliance with the following</p>
                <ul className="list-disc pl-5 mb-5 mt-2">
                    <li className='mb-1'>The (Indian) Information Technology Act, 2000 and the rules thereunder;</li>
                    <li className='mb-1'>The (Indian) Information Technology (Reasonable Security Practices and Procedures and Personal Information) Rules, 2011 (the "IT Rules");</li>
                    <li className='mb-1'>The Personal Data Protection Act, 2012, to the extent applicable;</li>
                    <li className='mb-1'>The European General Data Protection Regulation (Regulation (EU) 2016/679), to the extent applicable;</li>
                    <li className='mb-1'>The California Consumer Privacy Act of 2018 (“the CCPA”), to the extent applicable.</li>
                </ul>
                <p><strong style={{ color: 'black', fontWeight: 'bold' }}>CONSENT</strong></p>
                <p className="mt-1">To ensure that you have full knowledge in how we handle your personal data, you are advised to read and understand the terms of this Privacy Policy in detail. In particular, upon your log into our Website, regardless of whether you register with this Website, you shall be deemed to accept, agree, undertake and confirm that</p>
                <ul className="list-disc mt-2 pl-5 mb-5">
                    <li className='mb-1'>You, on the basis of your own free will and your necessary consent, agree to disclose your personal information to us;</li>
                    <li className='mb-1'>You will comply with all the terms and limitations of this Privacy Policy;
                    </li>
                    <li className='mb-1'>You agree that we may collect your information through your log into this Website, your registration with this Website, and/or your using the Services offered by us;
                    </li>
                    <li className='mb-1'>You agree to any changes and modifications that we may make to our Privacy Policy in the future; and
                    </li>
                    <li className='mb-1'>You agree that our branches, affiliates and employees may contact you in connection with the products and services that you may be interested in (unless you have indicated that you do not want to receive such information).
                    </li>
                </ul>

                <p><strong style={{ color: 'black', fontWeight: 'bold' }}>REGISTRATION</strong></p>
                <p className="mb-5 mt-1">The user shall be required to register on the Platform by creating an account, in order to avail our Services. While registering on the Platform, you are required to provide us with certain information about yourself.

                </p>

                <p><strong style={{ color: 'black', fontWeight: 'bold' }}>INFORMATION WE COLLECT</strong></p>
                <p className="mt-1"> When you used the Website, you consent to allow us to use cookies to track each of your activities and gather and keep all the information that you leave on this Website, including yet not restricted to your IP address, location, and other data. If you are willing to use the services offered by this Website, you will be expected to fill in and give the following two categories of information:
                </p>

                <ul className="list-disc pl-5 mt-2 mb-5">
                    <li className='mb-1'>Personal Information: Such information can assist us with verifying whether you are eligible to be registered as a member of this Website. It includes but is not limited to your name, residence address, mailing address, other certificates and corresponding numbers thereof that are given by your country or government to certify your personal information related to your identity as all other information that can help us to verify your identity (hereinafter referred to as ‘identity information’).
                    </li>

                    <li className='mb-1'>Service Information: Such information helps us to contact you and provide you with the services, including but not limited to your phone number, fax number, valid email address, mailing address, and information concerning your debit card and/or other accounts (hereinafter collectively referred to as ‘service information’).

                    </li>
                    <li className='mb-1'>Furthermore, you may provide additional information through your profile page which we shall collect such as your email address, profile picture, age, gender, location/address, contact information, payment information, and any other related data.
                    </li>
                </ul>
                <p className="mb-5 mt-1">When you use this Website or the services this Website offers, we might gather additional data necessary from our exclusive mailboxes or in other manners that we consider as in compliance with relevant requirements, for the purpose of improving the functions of this Website, upgrading your experience of involving this Website, and its services as well as the security thereof, or as is required by any court order, any applicable law, administrative regulation or any order of any other competent government agency.
                </p>
                <p className="mb-5 mt-1">If you visit any of links to third-party websites as are listed on this Website or any link of any of our third-party partners, you shall agree to and comply with the separate and independent Privacy Policy of such third-party website. We will not bear any liability for the contents and activities of such websites or the partners.
                </p>
                <p className="mb-5 mt-1">We also collect certain information either when the User provides it while using the Website or Services and / or automatically as the Users navigate through the Website (which may include usage details, location, IP address, device ID and type, browser type and language, the operating system used by device, access times, and information collected through web beacons and other tracking technologies and which is covered by this Privacy Policy).
                </p>
                <p className="mb-5 mt-1">If the Company has reasons to suspect that the information provided by any User is misleading, inaccurate, obsolete or incomplete, the Company reserves the right to refuse provision of the Services to the User, at its sole discretion without incurring any liability to the User or any third-party.
                </p>

                <p><strong style={{ color: 'black', fontWeight: 'bold' }}>USE OF THE INFORMATION COLLECTED</strong></p>
                <p className="mt-1"> We collect Personal Information only necessary for the following purposes:
                </p>
                <ul className="list-disc mt-2 pl-5 mb-5">
                    <li className='mb-1'>To create and maintain your account, including personalization to improve the user experience;
                    </li>

                    <li className='mb-1'>To send surveys or marketing communications to inform you of new products or Services or other information that may be of interest;
                    </li>
                    <li className='mb-1'>To improve support, marketing and promotional efforts, to analyze Platform usage, improve the Website’s content, layout and Service offerings.
                    </li>
                    <li className='mb-1'>To dispatch transaction-related communications such as welcome letters and billing reminders;
                    </li>
                    <li className='mb-1'>To notify the necessary information related to the Website and Services and your account on the Website from time to time;
                    </li>
                    <li className='mb-1'>To maintain records and provide you with an efficient, safe and customized experience while using the Website;
                    </li>
                    <li className='mb-1'>To analyze overall trends to help us improve the Website and Services, including displaying detailed analytics to users;
                    </li>
                    <li className='mb-1'>Verify your identity and prevent fraud or other unauthorized or illegal activity;
                    </li>
                    <li className='mb-1'>To comply with applicable laws, rules, and regulations;
                    </li>
                    <li className='mb-1'>To facilitate transactions (your information, whether public or private, will not be sold, exchanged, transferred, or otherwise provided to any other company on any grounds without your consent, except for where doing so is expressly for the purpose of completing the transaction you require);
                    </li>
                    <li className='mb-1'>To transfer the data and the rights associated therewith to a third party as part of reorganization or a sale of the assets of any division of the Company or the entire Company itself;
                    </li>
                    <li className='mb-1'>To facilitate artist-to-fan communication.
                    </li>
                    <li className='mb-1'>In any other way consistent with this Privacy Policy and to support the above activities.
                    </li>
                </ul>
                <p><strong style={{ color: 'black', fontWeight: 'bold' }}>DISCLOSURE OF THE INFORMATION COLLECTED</strong></p>
                <ul className="list-disc pl-5 mt-2 mb-2">
                    <li className='mb-1'>We may share your Personal Information with our service providers, business partners, data analytics providers, affiliates and other users/ website visitors.
                    </li>
                    <li className='mb-1'>If we are involved in major business transactions like merger, acquisition, financing due diligence, reorganization, bankruptcy, sale or purchase of assets, or transition of service to another provider, your information may be transferred as a part of such a transaction, as per applicable law and/or contract.
                    </li>
                    <li className='mb-1'>We do not store data without the specific consent of users, except as set out in this Privacy Policy.
                    </li>
                </ul>
                <p style={{ color: 'black' }}>Additionally,
                </p>
                <ul className="list-disc pl-5 mb-5">
                    <li className='mb-1'>We reserve the right to use or disclose your Personal Information in response to any statutory or legal requirements in order to protect our rights, the rights of our other users, data processors and any other person or property.
                    </li>
                    <li className='mb-1'>We will use or disclose your Personal Information if we believe it is necessary to share information in order to investigate, prevent, or take action regarding illegal activities, suspected fraud, situations involving potential threats to the physical safety of any person or property, violations of the Our Terms and Conditions and/ or other policies, or as otherwise required by law when responding to subpoenas, court orders and other legal processes.
                    </li>
                </ul>
                <p><strong style={{ color: 'black', fontWeight: 'bold' }}>DATA RETENTION</strong></p>
                <p className="mb-5 mt-1">We retain the Personal Information described under this Privacy Policy that users provide us on the Wesbite and in relation to the Services, for the longer of the following: (a) for the period the users remain active on the Wesbite and/ or use the Services and for an additional period of 6 (six) months or 180 (one hundred and eighty) days thereafter; or (b) for so long as may be required for compliance under applicable laws. Other user data shall be retained for the duration of the account activity. Upon a request for account deactivation by you, we shall implement complete data deletion from all systems within [--].
                </p>

                <p><strong style={{ color: 'black', fontWeight: 'bold' }}>DATA PROTECTION</strong></p>
                <p className="mb-5 mt-1">The security of Personal Information is important and we have implemented reasonably sufficient means to protect the same. However, no method of transmission over the Internet, or method of electronic storage, is 100% secure and we cannot guarantee its absolute security. We have implemented best industry practices and security policies, rules and technical measures to protect the data including Personal Information that is under the Company’s control from unauthorised access, improper use or disclosure, unauthorised modification and unlawful destruction or accidental loss. However, for any data loss or theft due to unauthorized access to the users’ electronic devices through which the users avail the Services, we shall not be held liable for any loss whatsoever incurred by the users or any third-party. If you are aware of any security flaws in our Website, please contact us immediately so that we can take appropriate action as soon as possible.
                    <br /> The Personal Information is also protected under the applicable laws of Singapore. The Company has assigned Mr./Ms. [--], who has been given the responsibility of safeguarding and managing the data for the Company as specified under the applicable laws.
                </p>

                <p><strong style={{ color: 'black', fontWeight: 'bold' }}>SHARING OF PERSONAL INFORMATION</strong></p>
                <p className="mb-5 mt-1">The Company may also share your Personal Information with third party service providers in relation to Services, such as parties involved in hosting and enabling the performance of our Services. However, the Company conducts reasonable background checks on such third party service providers prior to sharing your Personal Information with them.
                    However, we do not sell your Personal Information.
                </p>

                <p><strong style={{ color: 'black', fontWeight: 'bold' }}>INTERNATIONAL DATA TRANSFERS</strong></p>
                <p className="mb-5 mt-1">All or any information processed by the Company may be transferred, processed, and stored on the Company’s servers located anywhere in the world, including, but not limited to, the Singapore, United States, Europe, India, or such other countries, which may have different data protection laws from the laws in the country of your residence. We undertake to safeguard your information in accordance with the applicable laws.
                    <br /> In the event we transfer your Personal Information to countries outside the European Economic Area, we endeavour to put in place necessary safeguards to ensure that the said data transfer shall comply with the applicable laws and regulations. If you are a resident of the European Union and/or subject to the European General Data Protection Regulation (Regulation (EU) 2016/679), please reach out to us at our contact details below with the specifics of your request/s, and we will ensure that your data collected, stored, and/or used by us (if any) and their privacy are protected in accordance with the laws and regulations applicable in your jurisdiction. We would like to make sure that you are fully aware of your data protection rights, which may include the right to access, rectification, erasure, restrict or object to processing, data portability, etc. If you make a request under this head, we will endeavour to respond within 1 month.
                </p>

                <p><strong style={{ color: 'black', fontWeight: 'bold' }}>THIRD PARTY DISCLAIMER</strong></p>
                <p className="mb-5 mt-1">The Website may contain links to other websites. Please note that when users click on one of these links, they are entering another website over which we have no control and for which we will bear no responsibility. Often these websites require the user to enter their Personal Information. We encourage and recommend the users to read the privacy policies of all such websites as their policies may differ from our Privacy Policy. Users agree that we shall not be liable for any breach of their privacy or of their Personal Information or for any loss incurred by their use of such websites or services. The inclusions or exclusions are not suggestive of any endorsement by the Company of the website or contents of the website. The users may visit any third-party website linked to the Website, at their sole risk.
                    <br />Additionally, the Website may allow for certain content generated by the user (whether by way of testimonials or otherwise), which can be accessed by other users. Such users, including any moderators or administrators, are not authorized representatives or agents of the Company and their opinions or statements do not necessarily reflect those of the Company and we are not bound thereby to any contract to that effect. The Company expressly disclaims any liability arising out of any reliance on or misuse of such information that is made available by the users.
                </p>

                <p><strong style={{ color: 'black', fontWeight: 'bold' }}>RIGHTS OF THE USERS</strong></p>
                <p className="mt-1">As a user, the Company grants you complete control of the information that you have provided to us. The rights pertaining thereto are enumerated below:</p>
                <ul className="list-disc mt-2 pl-5 mb-5">
                    <li className='mb-1'>The right to access, update or delete the information We have on the User. This can be done by the users after sharing their request to the Company at please-reply@asset.money .
                    </li>
                    <li className='mb-1'>The right of rectification. Users have the right to have their information rectified if such information is inaccurate or incomplete.
                    </li>
                    <li className='mb-1'>The right to object and restrict. Users can object and restrict to the processing of their Personal Information. This may however, interfere with the provision of our Services to its optimum.
                    </li>
                    <li className='mb-1'>The right to data portability. Users have the right to be provided with a copy of the information We have on them in a structured, machine-readable and commonly used format.</li>
                    <li>The right to withdraw consent. Users have the right to withdraw their consent at any time, where consent is given pertaining to Personal Information.
                    </li>
                    <li className='mb-1'>The right to be informed. Users have the right to be informed about third parties with which their Personal Information has been shared.
                    </li>
                </ul>

                <p><strong style={{ color: 'black', fontWeight: 'bold' }}>DO RESIDENTS OF THE EUROPEAN ECONOMIC AREA (EEA, INCLUDING EU), SWITZERLAND AND THE UK HAVE SPECIFIC PRIVACY RIGHTS?
                </strong></p>
                <p className='mt-1'>This section provides additional information about Our Privacy Policy relevant to You if You are from the European Economic Area (the EEA), United Kingdom, and Switzerland (together “European Area Countries”). It supplements and should be read in conjunction with the rest of the Privacy Policy.
                </p>
                <p className='mt-1'>Under the European Area Countries’ privacy laws, we are the Controller with respect to Your data.
                </p>
                <p className='mt-1'>Where it is necessary to obtain Your prior consent to the processing concerned in order for Us to be allowed to do it (for example, direct marketing activities), We will obtain and rely on Your consent in relation to the processing concerned. Otherwise, We will process Your Personal Information only where the processing is necessary:</p>
                <ul className="list-disc pl-5 mt-2 mb-5">
                    <li className='mb-1'>for the performance of a contract to which You are a party or in order to take steps at Your request prior to entering into such a contract;
                    </li>
                    <li className='mb-1'>for the purposes of the legitimate interests (Art. 6(1)(f) GDPR) pursued by Us or another person, provided that this will only be in circumstances in which those legitimate interests are not overridden by Your interests or fundamental rights and freedoms which require protection of Personal Information;
                    </li>
                    <li className='mb-1'>for the fulfilment of Our legal obligations– the legal basis for processing of Your Personal Information will be the necessity for compliance with a legal obligation which We are subject to (Art. 6(1)(c) GDPR).
                    </li>
                </ul>
                <p className='mb-5 mt-1'>For information about the Personal Information that We collect and how We collect it, please refer to <strong>“INFORMATION COLLECTED”</strong> section of this Privacy Policy. The purposes for which We collect such Personal Information are provided under the section <strong>“USE OF THE INFORMATION COLLECTED”</strong>. The categories of third-parties with whom We may share Your Personal Information are listed in the <strong>“DISCLOSURE OF THE INFORMATION COLLECTED”</strong> section of this Privacy Policy.
                </p>
                <p style={{ color: 'black', fontWeight: 'bold' }}>Additional Privacy Rights for European Area Countries’ Residents
                </p>
                <p className='mt-1'>In addition to the rights provided under section <strong>“RIGHTS OF THE USERS”</strong>, the California residents are also entitled to the following rights:
                </p>
                <li className="mt-2">Data Erasure – In certain situations, GDPR gives You the “right to be forgotten.” You can invoke this right where one of the following grounds applies:
                </li>
                <p>
                    In certain situations, GDPR gives you the “right to be forgotten.” You can invoke this right where one of the following grounds applies:
                </p>
                <ol style={{ listStyleType: 'lower-alpha', paddingLeft: 50 }}>
                    <li className='mt-1'>The personal information is no longer necessary in relation to the purposes for which it was collected or otherwise processed.</li>
                    <li className='mt-1'>You withdrew consent to the processing of personal information and there is no other legal ground for continuing to process it.</li>
                    <li className='mt-1'>You object to the processing of your personal information when there are no overriding legitimate grounds for processing.</li>
                    <li className='mt-1'>You object to the processing of your personal information for marketing purposes.</li>
                    <li className='mt-1'>Your data is processed unlawfully.</li>
                    <li className='mt-1'>The personal information has to be erased for compliance with a legal obligation.</li>
                </ol>

                <li className="mt-2">Complaints to the Relevant Public Authority - In connection with Our actions as the controller of Your Personal Information, You are entitled to lodge a complaint to the relevant supervisory authority. You can find the list of local authorities responsible for data protection across the EU and their contact details at <a href="https://edpb.europa.eu/about-edpb/about-edpb/members_en" className="text-blue-500">EDPB Website</a>. The relevant public authority in the UK is the Information Commissioner’s Office (<a href="https://ico.org.uk/" className="text-blue-500">Information Commissioner’s Office</a>). The relevant public authority in Switzerland is the Federal Data Protection and Information Commissioner (FDPIC) (                    <a href="https://www.edoeb.admin.ch/edoeb/en/home.html" className="text-blue-500">Federal Data Protection and Information Commissioner (FDPIC)</a>).
                </li>
                <p className='mt-2 mb-5'>We encourage You to first contact Us at [--].
                    <br />You can obtain a copy of the security measures We apply for the transfer of Personal Information to third countries by contacting Us at [--].
                </p>

                <p style={{ color: 'black', fontWeight: 'bold' }}>DATA CONTROLLER AND DATA PROCESSOR; DATA PROTECTION OFFICER
                </p>
                <p className='mt-2'>With regard to personal information, We may collect from You or that You may provide, We process that Personal Information or “personal data” as a “controller,” as such terms are defined in the General Data Protection Regulation (<strong>“GDPR”</strong>). The Company has a Data Protection Officer (as defined in the GDPR) who is responsible for matters relating to privacy and data protection. Our Data Protection Officer can be reached at [--].
                </p>
                <p className='mt-2'>For Users located in the European Economic Area (EEA), all processing of Personal Information is performed in accordance with the GDPR.
                </p>
                <p className='mt-2 mb-4'>All personal information collected by Us through the Platform/Services are stored exclusively in secure hosting facilities provided by [--] We have a data processing agreement in place with our providers, ensuring compliance with the GDPR and all applicable regulations. All hosting is performed in accordance with industry-standard security controls. All transfers of data from the EEA are done in accordance with regulations.
                </p>


                <p><strong style={{ color: 'black', fontWeight: 'bold' }}>NOTICE FOR CALIFORNIA RESIDENTS</strong></p>
                <p className="mb-5 mt-1">This notice shall only apply to Our processing of Personal Information that is subject to the CCPA. The CCPA provides California residents with the right to know what categories of Personal Information We have collected about them and whether We have disclosed that Personal Information for a business purpose (for e.g., to a service provider) in the preceding 12 months.</p>
                <p className='my-1'><strong>Response Timing:</strong> We are required to respond to any of your verifiable consumer requests to exercise these above rights within 45 days. Should We be unable to comply within 45 days, We will contact You and let You know whether We can complete your request or whether We need more time to complete your request with reason. Any disclosure We provide will only cover the 12-month period preceding the verifiable consumer request’s receipt.
                </p>
                <p className='my-1'>Regarding information We sell or disclose to third parties, You have the right to opt out of such sale or disclosure at any time. You can exercise your right to opt out (or any other rights) on our website: <a href='https://www.asset.money/' target='_blank' className='underline text-blue-500'>Asset Money</a> or by emailing us at please-reply@asset.money.
                </p>
                <p className='mt-5'><strong style={{ color: 'black', fontWeight: 'bold' }}>SALES OF PERSONAL INFORMATION UNDER THE CCPA</strong></p>
                <p className="mb-5 mt-1">The Company does not sell Personal Information, nor do We possess any actual knowledge of any sale of Personal Information of minors under 16 years of age.
                </p>
                <p><strong style={{ color: 'black', fontWeight: 'bold' }}>ADDITIONAL PRIVACY RIGHTS FOR CALIFORNIA RESIDENTS</strong></p>
                <ul className="list-disc pl-5 mb-5 mt-2">
                    <li className='mt-1'><strong>Non-Discrimination.</strong> It is the right of California residents not to receive any discriminatory treatment from Us for the exercise of their rights conferred by the CCPA.
                    </li>
                    <li className='mt-1'><strong>Authorized Agent.</strong> Only the Users, or someone legally authorized to act on behalf of the Users, may make a verifiable consumer request related to their personal information. Users may also make a verifiable consumer request on behalf of their minor children. To designate an authorized agent, please contact Us at please-reply@asset.money and provide written authorization signed by the User and his/her designated agent.
                    </li>
                    <li className='mt-1'><strong>Verification.</strong> To protect Users’ privacy, We will undertake the following steps to verify Users’ identity before fulfilling their request. When You make a request, We will ask You to provide sufficient information that allows Us to reasonably verify that You are the person about whom We collected personal information or an authorized representative, which may include asking You for additional proof of identity and asking questions regarding your account and use of Our Services.
                    </li>
                    <li className='mt-1'><strong>California “Shine the Light” law.</strong> California’s “Shine the Light” law (California Civil Code Section 1798.83) permits Users of our Services that are California residents to request a list of third parties to whom We have shared any Personal Information belonging to such Users, for marketing purposes. To make such a request, please contact Us at please-reply@asset.money.
                    </li>
                    <li className='mt-1'><strong>Do not track.</strong> As per California law, CalOPPA (California Business & Professions Code Section 22575(a)), it is required for Us to let You know how We respond to web browser Do Not Track (“DNT”) signals. DNT is a privacy preference that Users may set in their web browsers. Please acknowledge that we do not respond to such DNT requests at this time.
                    </li>
                </ul>

                <strong style={{ color: 'black', fontWeight: 'bold' }}>COOKIES</strong>
                <p className="mt-1">A cookie is a small text file stored by a website in a user’s web browser that helps us in many ways to make your use of the Services more efficient and meaningful to you (“Cookie(s)”). They allow us to tailor the Service to better match your needs. A session Cookie is stored only in your computer’s working memory and only lasts for your browsing session. When you close all your browser’s windows, or when you shut down your computer, the session Cookie disappears forever. A persistent Cookie remains on your computer after You close your browser so that it can be used by your browser on subsequent visits to the Services. Persistent Cookies stay on your computer until either they expire or are overwritten with newer Cookies, or you manually remove them. Most browsers can be configured not to accept Cookies; however, this may prevent you from having access to some site functions or features.
                </p>
                <p className="mb-5 mt-2">When you visit our Website, we use Cookies to record our performance and check the effect of online advertising. Cookies are usually used to record the habits and preferences of visitors in browsing the items on our Website. The information collected by Cookies is non-registered and collective statistical data and does not involve personal data.
                </p>
                <strong style={{ color: 'black', fontWeight: 'bold' }}>‍MODIFICATION TO THE PRIVACY POLICY </strong>
                <p className="mb-5 mt-1">We reserve the right to amend the Privacy Policy from time to time and at any time. We will inform you of the modifications made to the Privacy Policy by updating and publishing the effective date of the release of new versions hereof and highlighting the amendments. Sometimes, we may issue a notice to inform you of the modification made in the Privacy Policy, which, however, is not an obligation for us. You shall regularly review the Privacy Policy and focus on its modifications, and if you do not agree to such modifications, you shall immediately stop accessing this Website. When an updated version of this Privacy Policy is released, your continued visit to this Website shall indicate and show that you agree to the update and agree to comply with the updated Privacy Policy.
                </p>
                <strong style={{ color: 'black', fontWeight: 'bold' }}>MINOR</strong>
                <p className="mb-5 mt-1">As provided in our Terms, our Service is not intended for anyone under the age of 18 or minor under the applicable law where the Services are accessed (“Minor”). We do not intend to or knowingly collect any Personal Data related to a Minor. If you believe that Personal Data of any minor has been collected or provided to us, please contact us.
                </p>
                <strong style={{ color: 'black', fontWeight: 'bold' }}>CONTACT DETAILS</strong>
                <p className=" mt-1">In the event you have any concerns with respect to contents of this Privacy Policy or would like to exercise any of your rights set out herein, you may contact Our Grievance Office by email or postal mail at the following addresses:</p>
                <p className="mt-1">Name of the Officer: [●]
                </p>
                <p className="mt-1">Email ID: [●]
                </p>
                <p className="mb-15 mt-1">Address: [●]
                </p>
            </div>
        </>
    );
};

export default PrivacyPolicy;