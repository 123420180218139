import { PastWinner } from "../../constants/dataConstant"
import ButtonElement from "../../components/ButtonElement"
import useGA from "../../hooks/useGA"
import useClevertap from "../../hooks/useClevertap"

type FandomFlexElementMobileProps = {
    concert: PastWinner
    index: number
}

const FandomFlexElementMobile = ({ concert, index }: FandomFlexElementMobileProps) => {

    const { recordGAEvent } = useGA()
    const { handleEventPushClick } = useClevertap()

    const handleClick = () => {
        recordGAEvent(`ViewWinner_${index + 1}_MZ`)
        handleEventPushClick(`ViewWinner_${index + 1}_MZ`, {})
        window.open(concert.link, "_blank");
    }

    const handleClickTwoWinners = (winner: string, link: string) => {
        recordGAEvent(`ViewWinner_${winner}_${index + 1}_MZ`)
        handleEventPushClick(`ViewWinner_${winner}_${index + 1}_MZ`, {})
        window.open(link, "_blank")
    }


    return (
        <div className="w-[160px] py-2 flex flex-col justify-center items-center bg-[#D9D9D9] flex-shrink-0">
            <img src={concert.image} className="w-[140px] h-[140px]" />
            <div className="font-indie-flower text-[18px] leading-[100%] text-[#000000] mt-2">
                {concert.artist}
            </div>
            <div className="font-indie-flower text-[16px] leading-[100%] text-[#000000] mt-1">
                {`${concert.date}, ${concert.city}`}
            </div>

            {concert.link === "coldplay_two_winners" ?
                <div className="flex justify-center gap-1 flex-col items-center mt-2">
                    <ButtonElement text="View Winner 1" disabled={false} clickHandler={() =>
                        handleClickTwoWinners(
                            "Winner1",
                            "https://www.instagram.com/reel/DExfEwHIM_I/?utm_source=ig_web_copy_link&igsh=MzRlODBiNWFlZA=="
                        )
                    } />
                    <ButtonElement text="View Winner 2" disabled={false} clickHandler={() =>
                        handleClickTwoWinners(
                            "Winner2",
                            "https://www.instagram.com/reel/DE2z9DUzEfQ/?utm_source=ig_web_copy_link&igsh=MzRlODBiNWFlZA=="
                        )
                    } />
                </div> :
                <div className="flex justify-center items-center mt-2">
                    <ButtonElement text="View Winner" disabled={false} clickHandler={handleClick} />
                </div>}

        </div>
    )
}

export default FandomFlexElementMobile
