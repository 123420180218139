import { useState, useRef, useEffect } from "react";
import { useLocation } from "react-router-dom";
import {
    ACTIVE_CONCERTS,
    ADITYA_RIKHARI,
    ANUV_JAIN_CONCERTS,
    BHARAT_CHAUHAN_TOUR,
    PAPER_KITES,
} from "../../constants/dataConstant";
import ActiveConcertElementDesktop from "./ActiveConcertElementDesktop";
import ActiveConcertElementMobile from "./ActiveConcertElementMobile";
import { FaAngleDown, FaChevronLeft, FaChevronRight } from "react-icons/fa";

const ActiveConcerts = () => {
    const [showAll, setShowAll] = useState(false);
    const location = useLocation();
    const scrollContainerRef = useRef<HTMLDivElement>(null);
    const [canScrollLeft, setCanScrollLeft] = useState(false);
    const [canScrollRight, setCanScrollRight] = useState(true);

    // Determine the appropriate data source based on the URL
    const getConcertData = () => {
        const sanitizedPath = location.pathname.replace(/[^a-zA-Z0-9]/g, '').toLowerCase();

        if (sanitizedPath === "/concerts") {
            return ACTIVE_CONCERTS;
        } else if (sanitizedPath.includes("anuvjain")) {
            return ANUV_JAIN_CONCERTS;
        } else if (sanitizedPath.includes("thepaperkites")) {
            return PAPER_KITES;
        } else if (sanitizedPath.includes("bharatchauhan")) {
            return BHARAT_CHAUHAN_TOUR;
        } else if (sanitizedPath.includes("adityarikhari")) {
            return ADITYA_RIKHARI;
        } else {
            return ACTIVE_CONCERTS;
        }
    };

    const concertData = getConcertData();
    const visibleConcerts = showAll ? concertData : concertData.slice(0, 6);



    const checkScrollPosition = () => {
        if (scrollContainerRef.current) {
            const { scrollLeft, scrollWidth, clientWidth } = scrollContainerRef.current;
            setCanScrollLeft(scrollLeft > 0); // Check if not at the start
            setCanScrollRight(scrollLeft + clientWidth < scrollWidth); // Check if not at the end
        }
    };

    const scrollLeft = () => {
        if (scrollContainerRef.current) {
            scrollContainerRef.current.scrollBy({
                left: -200,
                behavior: "smooth",
            });
        }
    };

    const scrollRight = () => {
        if (scrollContainerRef.current) {
            scrollContainerRef.current.scrollBy({
                left: 200,
                behavior: "smooth",
            });
        }
    };

    useEffect(() => {
        // Initial check
        checkScrollPosition();

        // Attach scroll listener
        const scrollContainer = scrollContainerRef.current;
        if (scrollContainer) {
            scrollContainer.addEventListener("scroll", checkScrollPosition);
        }

        return () => {
            if (scrollContainer) {
                scrollContainer.removeEventListener("scroll", checkScrollPosition);
            }
        };
    }, []);



    return (
        <div className="w-[100%] py-4 md:py-0 flex flex-col justify-center items-center bg-opacity-10 md:bg-opacity-100 rounded-md">

            {/* Desktop view */}
            <div className="w-[95%] hidden md:flex flex-row">
                <button onClick={scrollLeft}
                    disabled={!canScrollLeft}
                    className={`p-2 ${!canScrollLeft ? "opacity-50 cursor-not-allowed" : ""}`}>
                    <FaChevronLeft color="#FEC330" size={30} />
                </button>
                <>
                    <div
                        className={`hidden md:flex mt-0 w-full flex-row overflow-x-auto scrollbar-hide items-center ${concertData?.length <= 3 ? "justify-center gap-14 pt-0 pb-0" : location.pathname.includes("/concerts/") ? "justify-start gap-4 pt-0 pb-0" : "justify-start gap-4"
                            }`}
                        ref={scrollContainerRef}

                    >
                        {concertData.map((concert, index) => (
                            <ActiveConcertElementDesktop
                                key={`active_concert_${index + 1}`}
                                concert={concert}
                                index={index}
                            />
                        ))
                        }
                    </div>
                </>
                <button onClick={scrollRight}
                    disabled={!canScrollRight}
                    className={`p-2 ${!canScrollRight ? "opacity-50 cursor-not-allowed" : ""}`}>
                    <FaChevronRight color="#FEC330" size={30} />
                </button>
            </div>


            {/* Mobile view */}
            <div className={`flex md:hidden mt-4 flex-row w-screen overflow-x-auto ${concertData?.length <= 2 ? "justify-center" : "justify-start" } items-center gap-2`}>
                {
                    visibleConcerts.map((concert, index) => (
                        <ActiveConcertElementMobile
                            key={`active_concert_${index + 1}`}
                            concert={concert}
                            index={index}
                        />
                    ))
                }
            </div>
        </div>
    );
};

export default ActiveConcerts;
