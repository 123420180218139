import "../../index.css";
import Header from "./Header";
import Footer from "./Footer";
import Hero from "./Hero";
import AppHighlights from "./AppHighlights";
import AppFeatures from "./AppFeatures";
import Testimonials from "./Testimonials";
import Sponsors from "./Sponsors";

const HomePage = () => {
    return (
        <div className="w-screen flex flex-col justify-center items-center bg-[#0E0F0C] overflow-x-clip">
            {/*  */}
            <div className="h-[40px] md:h-[60px]">
                <h1 >Muzify - Track Music Stats, Earn Badges & Play Fun Games</h1>
            </div>

            {/* Header */}
            <Header />

            {/* Quiz and side carousels */}
            <Hero />

            {/* App Highlights */}
            <AppHighlights />

            {/* App features */}
            <AppFeatures />

            {/* Testimonials */}
            <Testimonials />

            {/* Sponsors */}
            <Sponsors />

            {/* Footer */}
            <Footer />

            <div className="w-screen h-[50px] flex justify-center items-center">
                <a className="text-sm" href="https://petplaylist.xyz/" target="_blank" rel="noreferrer">
                    Pet Playlist
                </a>
            </div>
        </div>
    )
}

export default HomePage;
