import useGA from "../../hooks/useGA";
import MuzifyLogo from "../../assets/images/logo-horizontal.png";
import { SOCIALS, PAGES, GA_EVENTS } from "../../constants/dataConstant"
import QRCode from "../../assets/images/onelink-qr-code.png"

const LogoComponent = () => {
    return (
        <div className="w-[100%] flex justify-center items-center">
            <img src={MuzifyLogo} className="w-[180px] md:w-[233.5px] h-[67.5px] md:h-[87.5px]" alt="Muzify Logo" />
        </div>
    )
}

const LinksComponent = () => {
    
    const { recordGAEvent } = useGA();
    
    const handlePagesClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent> , pageName: string, pageLink: string) => {
        event.preventDefault();
        if (pageName === "privacypolicy") {
            recordGAEvent(GA_EVENTS.PRIVACY_POLICY_CLICK);
        } else if (pageName === "tnc") {
            recordGAEvent(GA_EVENTS.TERMS_AND_CONDITIONS_CLICK);
        } else if (pageName === "partner") {
            recordGAEvent(GA_EVENTS.PARTNER_WITH_US_CLICK);
        } else if (pageName === "faq") {
            recordGAEvent(GA_EVENTS.FAQ_CLICK);
        }
        window.open(pageLink, "_blank");
    }

    return (
        <div className="w-[100%] flex flex-wrap justify-center gap-1 md:gap-4">
            {
                PAGES.map((page) => (
                    <button key={page.name}
                        onClick={(e) => handlePagesClick(e, page.name, page.link)}
                        className="font-inter text-[12px] md:text-[14px] leading-[16px] md:leading-[20px] underline underline-offset-4 text-[#1F1300] opacity-60"
                    >
                        {page.text}
                    </button>
                ))
            }
        </div>
    )
}

const SocialsComponent = () => {

    const { recordGAEvent } = useGA();

    const handleSocialsClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent> , socialName: string, socialLink: string) => {
        event.preventDefault();
        if (socialName === "instagram") {
            recordGAEvent(GA_EVENTS.INSTAGRAM_CLICK);
        } else if (socialName === "twitter") {
            recordGAEvent(GA_EVENTS.TWITTER_CLICK);
        } else if (socialName === "tiktok") {
            recordGAEvent(GA_EVENTS.TIKTOK_CLICK);
        } else if (socialName === "linkedin") {
            recordGAEvent(GA_EVENTS.LINKEDIN_CLICK);
        }
        window.open(socialLink, "_blank");
    }

    return (
        <div className="w-full flex flex-col justify-center items-center gap-y-1 md:gap-y-2">
            <div className="font-inter font-medium text-[14px] md:text-[20px] text-[#1F1300] text-center flex justify-center items-center">
                {`Stay tuned for your path to musical domination!`}
            </div>
            <div className="flex flex-row">
                {
                    SOCIALS.map((social) => (
                        <button
                            className="px-2"
                            key={social.name}
                            onClick={(e) => handleSocialsClick(e, social.name, social.link)}
                        >
                            {social.icon}
                        </button>
                    ))
                }
            </div>
        </div>
    )
}

const QRCodeElement = () => {
    return (
        <div className="w-full flex flex-col justify-center items-center">
            <div className="font-inter font-medium text-[14px] md:text-[20px] text-[#1F1300] flex justify-center items-center">
                Download App
            </div>
            <div className="flex justify-center items-center">
                <img src={QRCode} className="w-[130px] h-[130px]" alt="QR code for app"/>
            </div>
        </div>
    )
}

const MobileFooterView = () => {
    return (
        <div className="flex flex-col justify-center items-center gap-y-4">
            <LogoComponent />
            <SocialsComponent />
            <LinksComponent />
        </div>
    )
}

const DesktopFooterView = () => {
    return (
        <div className="hidden md:flex w-full justify-around items-center">
            <div className="w-[2/3] flex flex-col justify-center items-center gap-y-8">
                <LogoComponent />
                <LinksComponent />
            </div>
            <div className="w-[1/3] flex flex-col justify-center items-center gap-y-12">
                <SocialsComponent />
                <QRCodeElement />
            </div>
        </div>
    )
}

const Footer = () => {
    return (
        <div className="w-screen bg-[#0E0F0C] flex justify-center items-center">
            <div className="w-[90%] h-[273px] md:h-[338px] flex justify-center items-center bg-[#FEC330] rounded-xl">
                <div className="w-full h-full flex justify-center items-center md:hidden">
                    <MobileFooterView />
                </div>
                <div className="hidden w-full h-full md:flex justify-center items-center ">
                    <DesktopFooterView />
                </div>
            </div>
        </div>
    )
}

export default Footer;
