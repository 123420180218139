import { useLocation } from "react-router-dom";
import { PAST_CONCERTS_WINNERS } from "../../constants/dataConstant";
import FandomFlexElementMobile from "./FandomFlexElementMobile";
import FandomFlexElementDesktop from "./FandomFlexElementDesktop";
import { FaAngleDown, FaChevronLeft, FaChevronRight } from "react-icons/fa";
import { useEffect, useRef, useState } from "react";

const FandomFlex = () => {
    const location = useLocation(); // Get the current location

    const scrollContainerRef = useRef<HTMLDivElement>(null);
    const [canScrollLeft, setCanScrollLeft] = useState(false);
    const [canScrollRight, setCanScrollRight] = useState(true);
    // Check if the URL is exactly '/concerts'

    const checkScrollPosition = () => {
        if (scrollContainerRef.current) {
            const { scrollLeft, scrollWidth, clientWidth } = scrollContainerRef.current;
            setCanScrollLeft(scrollLeft > 0); // Check if not at the start
            setCanScrollRight(scrollLeft + clientWidth < scrollWidth); // Check if not at the end
        }
    };

    const scrollLeft = () => {
        if (scrollContainerRef.current) {
            scrollContainerRef.current.scrollBy({
                left: -200,
                behavior: "smooth",
            });
        }
    };

    const scrollRight = () => {
        if (scrollContainerRef.current) {
            scrollContainerRef.current.scrollBy({
                left: 200,
                behavior: "smooth",
            });
        }
    };

    useEffect(() => {
        // Initial check
        checkScrollPosition();

        // Attach scroll listener
        const scrollContainer = scrollContainerRef.current;
        if (scrollContainer) {
            scrollContainer.addEventListener("scroll", checkScrollPosition);
        }

        return () => {
            if (scrollContainer) {
                scrollContainer.removeEventListener("scroll", checkScrollPosition);
            }
        };
    }, []);

    if (location.pathname.includes("/concerts/")) {
        return null;
    }

    return (
        <div className="md:w-[100%] mt-4 py-4 md:py-8 flex flex-col justify-center items-center bg-opacity-10 md:bg-opacity-100 rounded-md">
            <div className="flex justify-center items-center font-inter font-extralight italic text-[24px] md:text-[36px] leading-[110%] text-[rgba(255,255,255,0.8)] text-opacity-60">
                {`Fandom Flex`}
            </div>
            <div className="w-[95%] hidden md:flex flex-row">
                <button onClick={scrollLeft}
                    disabled={!canScrollLeft}
                    className={`p-2 ${!canScrollLeft ? "opacity-50 cursor-not-allowed" : ""}`}>
                    <FaChevronLeft color="#FEC330" size={30} />
                </button>
                <div className="hidden md:flex mt-6 flex-row scrollbar-hide justify-start overflow-x-auto w-screen items-center gap-4"
                    ref={scrollContainerRef}
                >
                    {
                        PAST_CONCERTS_WINNERS.map((pastWinner, index) => {
                            return <FandomFlexElementDesktop key={`active_concert_${index + 1}`} concert={pastWinner} index={index} />;
                        })
                    }
                </div>
                <button onClick={scrollRight}
                    disabled={!canScrollRight}
                    className={`p-2 ${!canScrollRight ? "opacity-50 cursor-not-allowed" : ""}`}>
                    <FaChevronRight color="#FEC330" size={30} />
                </button>
            </div>
            <div className="flex md:hidden mt-4 w-screen flex-row overflow-x-auto gap-4 scrollbar-hide">
                {PAST_CONCERTS_WINNERS.map((pastWinner, index) => (
                    <FandomFlexElementMobile
                        key={`active_concert_${index + 1}`}
                        concert={pastWinner}
                        index={index}
                    />
                ))}
            </div>
        </div>
    );
};

export default FandomFlex;
