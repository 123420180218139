import ConcertPageLogo from "../../assets/images/concert-page-logo.png"
import ActiveConcerts from "./ActiveConcerts"
import HowToEnter from "./HowToEnter"
import HowToEnterDesktop from "./HowToEnterDesktop"
import FandomFlex from "./FandomFlex"
import BackgroundImage from "../../assets/images/BackgroundConcert.png"
import BackgroundMobile from "../../assets/images/BackgroundMobile.png"
import BackgroundMobile2 from "../../assets/images/BackgroundMobile2.png"
import { useEffect, useState } from "react"
import ConcertsBG from "../../assets/images/ConcertBg.png"
import MobileBG from "../../assets/images/MobileBG.png"
import { useLocation } from "react-router"
import { ACTIVE_CONCERTS, ANUV_JAIN_CONCERTS } from "../../constants/dataConstant"

const ConcertsPage = () => {
    const [isMobile, setIsMobile] = useState(false);
    const location = useLocation(); // Get the current URL path

    useEffect(() => {
        const updateScreenSize = () => {
            setIsMobile(window.innerWidth <= 768); // Adjust breakpoint as needed
        };

        updateScreenSize();
        window.addEventListener("resize", updateScreenSize);
        return () => window.removeEventListener("resize", updateScreenSize);
    }, []);
    const pathname = location.pathname.toLowerCase(); // Normalize to lowercase
    const sanitizedPath = pathname.replace(/-/g, ""); // Remove hyphens

    let artistName = "";
    if (sanitizedPath.includes("/concerts/anuvjain")) {
        artistName = "Anuv Jain";
    } else if (sanitizedPath.includes("/concerts/thepaperkites")) {
        artistName = "The Paper Kites";
    } else if (sanitizedPath.includes("/concerts/bharatchauhan")) {
        artistName = "Bharat Chauhan";
    } else if (sanitizedPath.includes("/concerts/adityarikhari")) {
        artistName = "Aditya Rikhari";
    }


    return (
        <>
            <div
                className="w-screen bg-[#0E0F0C] hidden md:flex flex-col justify-center items-center"
                style={{
                    backgroundImage: `url(${BackgroundImage})`,
                    backgroundSize: "cover",
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: "center", // Keeps the image centered
                }}
            >

                <div className="flex items-center" onClick={() => window.open("https://muzify.com")}><img src={ConcertPageLogo} className="w-[240px] h-[90px] object-cover" /></div>

                {/* Header Text */}
                <div className="mt-4 w-full flex justify-center items-center">
                    <div className="w-[90%] flex flex-col justify-center items-center gap-2">
                        {pathname.includes("/concerts/") ? (
                            <div className="flex flex-col">
                                <div className="flex flex-row flex-wrap items-center justify-center">
                                    <h1 className="font-inter text-[#FFFFFF] font-bold text-center text-[28px] md:text-[50px] leading-[22px] md:leading-[45px]">
                                        Win
                                    </h1>
                                    <h1 className="font-inter ml-2 text-[#FFFFFF] font-bold text-center italic text-[28px] md:text-[50px] leading-[22px] md:leading-[45px]">
                                        free
                                    </h1>
                                    <h1 className="font-inter text-[#FFFFFF] font-bold text-center text-[28px] md:text-[50px] ml-2 leading-[22px] md:leading-[45px]">
                                        tickets to {artistName}’s tour
                                    </h1>

                                </div>
                                <h1 className="font-inter text-[#FFFFFF] font-normal text-center text-[20px] md:text-[30px] leading-[24px] md:leading-[45px]">
                                    powered by Muzify
                                </h1>
                            </div>
                        ) : (
                            <>
                                <h1 className="font-inter text-[#FFFFFF] font-bold text-center text-[28px] md:text-[56px] leading-[22px] md:leading-[45px]">
                                    Play Muzify, Win Big!
                                </h1>
                                <h1 className="font-inter mt-1 text-[#FFFFFF] font-normal text-center text-[28px] md:text-[28px] leading-[22px] md:leading-[50px]">
                                    Free concert tickets or $50 gift cards await!
                                </h1>
                            </>
                        )}

                    </div>
                </div>

                {/* Concert Gallery */}
                <ActiveConcerts />
                <FandomFlex />

                <div className="w-full flex md:hidden mt-8 flex-col justify-center items-center gap-8">
                    <HowToEnter />
                </div>

                <div className="w-full hidden md:flex mt-8 flex-col justify-center items-center gap-16">
                    <HowToEnterDesktop />
                </div>

                <div className="h-[32px] md:h-[64px]" />
            </div>
            <div className="flex flex-col md:hidden bg-[#0E0F0C] w-screen">
                {/* Upper Section */}
                <div className="w-screen flex flex-col justify-center items-center relative">
                    {/* Background Container with Reduced Opacity */}
                    <div
                        className="absolute inset-0"
                        style={{
                            backgroundImage: `url(${BackgroundMobile})`,
                            backgroundSize: "cover",
                            backgroundRepeat: "no-repeat",
                            backgroundPosition: "center",
                            opacity: 0.7, // Set the desired opacity for the background
                            zIndex: 0, // Ensure it is behind the content
                        }}
                    />

                    {/* Content Container with Full Opacity */}
                    <div className="relative z-10 w-full flex flex-col justify-center items-center min-h-screen"> {/* Changed to min-h-screen */}
                        <div className="flex mt-4 items-center" onClick={() => window.open("https://muzify.com")}>
                            <img src={ConcertPageLogo} className="w-[190px] h-[70px] object-cover" />
                        </div>

                        {/* Header Text */}
                        <div className="mt-4 w-full flex justify-center items-center">
                            <div className="w-[100%] flex flex-col justify-center items-center gap-2">
                                <div className="flex justify-center flex-wrap gap-2 items-center">
                                    {location.pathname.includes("/concerts/") ? (
                                        <div className="flex w-screen flex-row flex-wrap items-center justify-center">
                                            <h1 className="font-inter text-[#FFFFFF] font-bold text-center text-[28px] md:text-[56px] leading-[30px] md:leading-[45px]">
                                                Win
                                            </h1>
                                            <h1 className="font-inter mx-2 text-[#FFFFFF] font-bold text-center italic text-[28px] md:text-[56px] leading-[30px] md:leading-[45px]">
                                                free tickets to
                                            </h1>
                                            <h1 className="font-inter text-[#FFFFFF] font-bold text-center text-[28px] md:text-[50px] leading-[30px] md:leading-[45px]">
                                                {artistName}’s tour
                                            </h1>
                                            <h1 className="font-inter text-[#FFFFFF] font-normal mt-2 text-center text-[23px] md:text-[40px] leading-[30px] md:leading-[45px]">
                                                powered by Muzify
                                            </h1>
                                        </div>
                                    ) : (
                                        <>
                                            <h1 className="font-inter text-[#FFFFFF] font-bold text-center text-[30px] md:text-[56px] leading-[22px] md:leading-[45px]">
                                                Play Muzify, Win Big!
                                            </h1>
                                            <h1 className="font-inter mt-1 w-[90%] text-[#FFFFFF] font-normal text-center text-[20px] md:text-[56px] leading-[30px] md:leading-[50px]">
                                                Free concert tickets or $50 gift cards await!
                                            </h1>
                                        </>
                                    )}
                                </div>

                            </div>
                        </div>

                        {/* Concert Gallery */}
                        <ActiveConcerts />
                        <FandomFlex />
                        <div
                            className="flex flex-col justify-center items-center"
                            style={{
                                backgroundImage: `url(${BackgroundMobile2})`,
                                backgroundSize: "cover", // Ensures the image fills the container
                                backgroundRepeat: "no-repeat",
                                backgroundPosition: "center",
                                minHeight: "90vh", // Ensures full height
                            }}
                        >
                            <HowToEnter />

                        </div>
                        <div className="h-[32px] md:h-[64px]" />
                    </div>
                </div>

                {/* Lower Section */}
                {/* */}
            </div>


        </>
    );
};

export default ConcertsPage;
