import AppScreenImage from "../../assets/images/AppScreen.webp";
import StatsImage from "../../assets/images/StatsImage.png";
import StreakImage from "../../assets/images/StreakImage.png";
import BadgeImage from "../../assets/images/BadgeImage.png";
import FandomScorecardImage from "../../assets/images/FandomScorecardSample.webp";
// import { FaApple } from "react-icons/fa";
import useGA from "../../hooks/useGA";
import { GA_EVENTS } from "../../constants/dataConstant";
import useClevertap from "../../hooks/useClevertap";

const AppHighlights = () => {

    const { recordGAEvent } = useGA();
    const { handleEventPushClick } = useClevertap();

    const handleCTAClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        event.preventDefault();
        recordGAEvent(GA_EVENTS.PHONE_CTA_CLICK);
        handleEventPushClick(GA_EVENTS.PHONE_CTA_CLICK, {});
        window.open("https://muzify.onelink.me/PNu3/39ussg3x", "_blank");
    }

    return (
        <div className="block mb-[20px] md:mb-0 w-screen h-[500px] md:h-[620px] bg-app-highlights-phone md:bg-app-highlights bg-cover relative overflow-x-clip">
            <div className="absolute top-[100px] md:-top-[100px] left-1/2 transform -translate-x-1/2 z-1">
                <img className="w-[211px] md:w-[353px] h-[430px] md:h-[720px]" src={AppScreenImage}/>
                <div className="block absolute left-0 bottom-0 w-[100%] h-[50%] bg-gradient-to-t from-[#0E0F0C] via-[#0E0F0C] to-transparent z-5">
                </div>
                <div className="absolute top-[60%] md:top-[37.5%] -right-[35%] md:-right-[51%] z-2 opacity-90 overflow-visible">
                    <img className="w-[97px] md:w-[221px] h-[70px] md:h-[160px]" src={StreakImage}/>
                </div>
                <div className="absolute top-[12%] md:top-[28%] -left-[35%] md:-left-[55%] z-2 opacity-90 overflow-visible">
                    <img className="w-[117px] md:w-[265px] h-[30px] md:h-[70px]" src={StatsImage}/>
                </div>
            </div>

            <div className="absolute flex flex-col justify-center items-center gap-y-0 md:gap-y-12 top-[440px] md:top-[420px] left-1/2 transform -translate-x-1/2 z-10">
                <div className="w-[314px] md:w-[536px] flex justify-center items-center font-firaSans font-semibold text-[30px] md:text-[64px] leading-[110%] md:leading-[65px] text-[#F4ECE6] text-center">
                    FLEX YOUR MUSIC FANDOM
                </div>
                <button className="w-[110px] md:w-[150px] h-[32px] md:h-[48px] mt-[10px] md:mt-[20px] md:hidden flex justify-center items-center bg-[#FEC330] rounded-full"
                    onClick={(e) => handleCTAClick(e)}
                >
                    <div className="flex flex-row justify-center items-center font-inter font-medium text-[12px] md:text-[16px] leading-[120%] text-[#141A1E]">
                        <div>Get App</div>
                    </div>
                </button>
                <div className="hidden md:flex justify-center items-center">
                    <div className="font-inter font-medium text-[#FEC330] text-[22px] leading-[120%]">
                        {`Now available on Appstore & Playstore!`}
                    </div>
                </div>
            </div>

            <div className="absolute bottom-[42%] right-[4%] rotate-[-27.89deg] md:bottom-[20%] md:-right-[5%] md:rotate-[36deg] z-2">
                <img className="w-[120px] md:w-[300px] h-[120px] md:h-[300px]" src={BadgeImage}/>
            </div>

            <div className="absolute bottom-[12%] left-[4%] rotate-[-8.73deg] md:bottom-[10%] md:left-[5%] z-2">
                <img className="w-[125px] md:w-[250px] h-[162px] md:h-[350px]" src={FandomScorecardImage}/>
            </div>
            
        </div>
    )
}

export default AppHighlights;
